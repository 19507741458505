<template>
  <div class="article">
    <div class="sale_policy">
      <p>
        尊敬的各位用户，欢迎登录”库课在线”平台（以下简称“库课在线”或“本平台”）用户，在登录前请仔细阅读如下服务条款：
        本平台由库课文化科技股份有限公司(以下简称“本公司”)运营管理，并根据本协议的规定向内部相关事业部机构的员工提供服务。本协议双方为库课在线用户与本公司，适用于库课在线用户登录并使用本平台服务的全部内容。
        在登录本平台之前，请务必认真阅读并充分理解本协议全部内容。点击勾选我已阅读并同意用户协议和隐私政策，上述程序完成时即视为已经充分理解和同意本协议全部内容，并签署了本协议。使用本平台服务的全部内容将受到本协议的约束并应承担相应的责任和义务。
      </p>
      <p>
        <b>1、协议组成说明</b>
      </p>
      <p>
        1.1
        本协议的内容包括本协议以下全部条款以及本平台已经公示发布的及将来可能公示发布的各项规则，该等规则均为本协议不可分割的一部分，与本协议以下所列条款具有同等法律效力。
      </p>
      <p>
        1.2
        本平台有权根据需要修改本协议的内容。如以下条款或本平台各项规则有任何变更，本平台将在平台上刊载公告，不再向用户作个别通知。经修订的相关条款和规则一经公告，即于公告规定的特定生效日期自动生效。请及时关注本平台关于相关条款和规则的公告，如不同意该变更，请在变更公告刊载之日起72小时内以在本平台注册时提供的手机号向本平台公布的意见反馈栏终止本协议（"注册终止申请"），本平台确认收到意见反馈消息后将与用户协商本协议终止后双方义务的履行。如用户在本条所述时限内未发送登录终止申请，则本条所述时限届满之时，视为用户已经同意接受该等变更，并应遵照修改后的协议履行应尽义务，经修订的相关条款和规则一经公告，即于公告规定的特定生效日期自动生效并对用户产生法律约束力。
      </p>
      <p>
        1.3
        本协议不涉及用户与本平台的其他用户之间因网上交易而产生的法律关系及法律纠纷。但用户在此不可撤销的同意，将全面接受并履行与本平台其他用户在本平台签订的任何电子法律文本，并承诺按照该法律文本享有和（或）放弃相应的权利、承担和（或）豁免相应的义务。
      </p>
      <br />
      <p>
        <b>2、平台服务内容及服务费用</b>
      </p>
      <p>
        2.1
        本平台网络服务的具体内容由库课在线根据实际情况提供，包括学员报名录入、订单管理、学员管理、销售业绩、排课管理相关应用服务。
      </p>
      <p>
        2.2
        库课在线提供的网络服务仅限于用户在公司组织机构的相关分校和相关对应的权限的来提供服务支持。
      </p>
      <p>
        除特别说明外，库课在线包括但不限于PC端和移动端观看使用，所有服务均附期限，到期终止。用户员工应在离职前终止提供服务。
      </p>
      <br />
      <p>
        <b>3、用户使用规则</b>
      </p>
      <p>
        3.1
        用户不得利用本平台或本平台服务从事任何不符合中国法律法规或侵犯他人权益的活动。不得自行发布、转载、传送含有下列内容之一的信息，本平台在发现用户从事该等活动或发布信息时，有权基于本平台的独立判断直接删除用户在本平台上作出的相关信息，有权不经通知而立即停止用户对本平台的全部或部分功能的使用（包括但不限于中止、终止、限制用户使用本平台服务）而无需通知用户，亦无需承担任何责任。如因此给本公司或本平台造成损失的，应当赔偿损失。
      </p>
      <p>（1） 违反宪法确定的基本原则的；</p>
      <p>（2） 危害国家安全，泄露国家机密，颠覆国家政权，破坏国家统一的；</p>
      <p>（3） 损害国家荣誉和利益的；</p>
      <p>（4） 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（5） 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（6） 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>（7） 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
      <p>（8） 侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>（9） 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
      <p>（10） 以非法民间组织名义活动的；</p>
      <p>（11） 含有法律、行政法规禁止的其他内容的。</p>
      <p>
        3.2
        在使用本平台时，用户不得违反下述规定，如违反任意一条，本平台有权基于独立判断直接删除用户在本平台上作出的相关信息，有权要求用户改正或直接采取必要的措施禁止（包括但不限于中止、终止、限制用户使用本平台服务）而无需通知用户，亦无需承担任何责任。如因此给本公司及本平台造成损失的，用户应当赔偿本公司及本平台的损失。
      </p>
      <p>（1） 不得为任何非法目的而使用网络服务系统；</p>
      <p>（2） 遵守所有与网络服务有关的网络协议、规定和程序；</p>
      <p>
        （3） 不得利用本平台进行任何可能对互联网的正常运转造成不利影响的行为；
      </p>
      <p>
        （4） 不得利用本平台进行任何不利于本平台或侵犯本公司利益的行为或言论。
      </p>
      <p>3.3 账户安全及管理：</p>
      <p>
        3.3.1
        用户登录时向本平台提交的用户电话、密码及安全问题答案是用户在本平台的唯一识别信息。用户登录成功后，应当妥善保管用户名和密码，不得将自己用户账户或密码有偿或无偿以转让、出借、授权等方式提供给第三人操作和使用,
        否则用户应当自行承担因违反此要求而遭致的任何损失。违反本项约定的，库课在线保留收回账号的权利。若因违反本约定对他人造成损害的，用户应与实际使用人承担连带赔偿责任，同时库课在线保留追究用户违约责任的权利。
      </p>
      <p>
        3.3.2
        协助义务：用户如发现有第三人冒用或盗用用户账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知本平台，要求本平台暂停相关服务，否则由此产生的一切责任由用户自担。同时，用户理解并同意本平台对其提出的请求采取行动需要合理期限，在此之前，本平台对第三人使用该服务所导致的损失不承担任何责任。
      </p>
      <p>
        3.3.3
        用户同意，在必要时，本平台无需进行事先通知即有权终止提供用户账户服务，并可能立即暂停、关闭或删除用户账户及该用户账户中所有相关资料及档案。
      </p>
      <p>
        3.3.4
        账户注销：当用户决定不再使用账户时，可以在库课在线APP内注销功能正式注销账户。
      </p>
      <p>
        3.3.5
        用户同意，账户的暂停、中断或终止不代表用户责任的终止，用户仍应对使用本平台服务期间的行为承担可能的违约或损害赔偿责任，同时本平台仍可保有用户的相关信息。
      </p>
      <p>3.4 知识产权：</p>
      <p>
        3.4.1
        本平台的所有内容，包括但不限于文本、信息数据、学员信息、源代码和其他所有信息，均由本公司及本平台享有知识产权。未经本平台事先书面同意，不得复制、模仿、改编、传播、公布、展示或以任何其他方式侵犯本平台的知识产权。未经本公司书面同意,用户不得将本平台包含的资料等任何内容发布到任何其他平台或者服务器。任何未经授权对本平台内容的使用均属于违法行为，本公司有权追究法律责任。
      </p>
      <p>
        3.4.2
        用户不得对库课在线平台或其内容进行转售或商业利用；不得收集和利用产品的信息和功能；不得对库课在线平台或其内容进行任何衍生利用；不得为其他商业利益而下载或拷贝账户信息或使用任何数据采集、收集和摘录工具。未经本平台的书面许可，严禁对库课在线平台的内容进行系统获取以直接或间接创建或编辑文集、数据库或人名地址录（无论是否通过Robots、Spiders、自动仪器或手工操作）。另外，严禁为任何未经本协议明确允许的目的而使用库课在线平台上的内容和材料。
      </p>
      <br />
      <p>
        <b>4、用户隐私保护及授权</b>
      </p>
      <p>
        4.1
        用户同意本平台在业务运营中收集和储存用户信息，包括但不限于用户自行提供的资料和信息，以及本平台自行收集、取得的用户在本平台的交易记录和使用信息等。本平台收集和储存用户信息的主要目的在于提高服务的效率和质量。
      </p>
      <p>
        4.2
        本平台不对外公开或第三方提供单个用户的注册资料及用户在使用网络服务时存储在本平台的非公开内容，但下列情况除外：
      </p>
      <p>（1） 事先获得用户的书面明确授权；</p>
      <p>（2） 根据有关的法律法规要求；</p>
      <p>（3） 按照相关政府主管部门的要求；</p>
      <p>（4） 为维护社会公众的利益；</p>
      <p>（5） 为维护库课网的合法权益；</p>
      <p>
        4.3
        本平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本站同等的保护用户隐私的责任，则本站有权将用户的注册资料等提供给该第三方。
      </p>
      <p>
        4.4
        本平台按照用户在本平台上的行为自动追踪关于用户的某些资料。在不透露注册用户的隐私资料的前提下，本平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
      </p>
      <p>
        4.5 用户同意，本公司及本平台可在本平台的某些网页上使用数据收集装置。
      </p>
      <br />
      <p>
        <b>5、责任限制</b>
      </p>
      <p>
        5.1
        基于互联网的特殊性，本公司无法保证本平台的服务不会中断，对于包括但不限于本公司、本平台及相关第三方的设备、系统存在缺陷，计算机发生故障、遭到病毒、黑客攻击或者发生地震、海啸等不可抗力而造成服务中断或因此给用户造成的损失，本公司不承担任何责任，有关损失由用户自担。
      </p>
      <p>
        5.2
        本公司无义务监测本平台内容。用户对于本平台披露的信息、选择使用本平台提供的服务，由此而产生的法律责任和后果由用户自担，本公司不承担任何责任。
      </p>
      <br />
      <p>
        <b>6、风险提示</b>
      </p>
      <p>
        6.1
        用户了解并认可，任何通过本平台进行的交易并不能避免以下风险的产生，本平台不能也没有义务为如下风险负责：
      </p>
      <p>（1）不可抗力因素导致的风险；</p>
      <p>
        （2） 因用户的过错导致的任何损失
        ，该过错包括但不限于：操作不当、遗忘或泄露密码、密码被他人破解、用户使用的计算机系统被第三方侵入、用户委托他人代理交易时他人恶意或不当操作而造成的损失。
      </p>
      <br />
      <p>
        <b>7、其他</b>
      </p>
      <p>
        7.1
        本协议的订立、执行、解释及争议解决均适用中华人民共和国法律（不包括香港、澳门、台湾地区）。本协议条款具有可分性，部分无效不影响其它部分效力。本平台对本协议拥有最终的解释权。
      </p>
      <p>
        7.2
        如果你是本平登录用户，本协议条款将对用户持续有效，有效期至用户注销或者被关闭账户后。
      </p>
      <p>7.3 如有任何争议，应友好协商解决，协商不成，应提交本公司内部解决。</p>
      <br />
      <p>
        <b>8、版权政策与用户侵权处理办法</b>
      </p>
      <p>
        相据《中华人民共和国著作权法
        》、《信息网络传播权保护条例》、《互联网著作权行政保护办法》、《互联网视听节目服务管理规定》等相关法律、法规的规定。
        库课在线/库课文化科技股份有限公司（以下简称库课或公司）为保护用户和版权方的合法权益，针对库课在线根据用户协议提供的服务，未经官方授权在各类平台上载、传播的信息网络侵权行为，采取如下版权保护措施：
      </p>
      <p>
        8.1
        库课履行对网络版权合理、审慎的保护义务，有理由确信存在明显侵犯公司版权服务的上载、传播行为，有权在不事先通知的情况下向第三方平台进行申诉，要求删除该涉嫌浸权作品；
      </p>
      <p>
        8.2
        库课对有证据证明存在未经授权的情况下录屏、上载、传播、复制、泄露用户信息数据等版权服务的侵权行为，有权在不事先通知的情况下，对涉嫌侵权行为的用户，停止该用户在库课平台的所有服务，封禁该用户在库课平台的账户权限。
      </p>
      <p>
        8.3
        对于有证据证明具有侵权行为，并在任何平台、以任何方式实现非法侵权获利的用户，库课将与该类用户共同协商解决办法，在协商过程中，用户需积极配合，保证协商流程正常、有效的进行；协商过后，用户需在一周内对协商结果进行无条件执行；否则，库课将通过法律程序解决。
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.article {
  width: 100%;
  padding: 0.3rem 0.3rem 0.4rem;
  box-sizing: border-box;
  font-size: 0.4rem;
  line-height: 0.6rem;
  color: #494949;
}
</style>
>
